<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading
                        ><span class="red--text">南</span>行徳SOCOLA店</heading
                    >
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >東西線東西線南行徳駅徒歩8分!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >千葉県市川市南行徳2丁目20-25 SOCOLA南行徳１F</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日12:00～21:00/土日12:00～21:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >なし</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="top center"
                            height="360px"
                            :src="imageMv"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="bottom center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1635819928042!6m8!1m7!1svAKBhPh21sWE90j-25Bfng!2m2!1d35.66801817176685!2d139.9054013767851!3f65.0119502921636!4f1.7876893749327536!5f0.7820865974627469"
                            width="100%"
                            height="360px"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                        ></iframe>
                        <v-card-text
                            >※Googleストリートビューの画像ではREVOISTの看板が表示されていない場合がありますが実際には店舗前に掲示されています。</v-card-text
                        >
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message :message="this.message" :shopImage="trainerImage" />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">南行徳駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">1</span>.
                            改札を出ましたら、左の南口の階段を降りてください。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">2</span>.
                            階段を降りて、右に曲がり進むとファミリーマートやセブンイレブンがございます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">3</span>.
                            Ｔ字路を左に曲がり、AOKIの方へ進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">4</span>.
                            AOKIの建物を右に曲がると南行徳駅前通りという大きな道路にでます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">5</span>.
                            大通り沿いに左に曲がると東京靴流通センターがあります。その道を真っすぐ進みます。信号もありますが、直進します。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessE"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">6</span>.
                            直進すると、左にSOCOLA南行徳、右にヤマダ電機の建物が見えます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessF"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">7</span>.
                            SOCOLA南行徳の正面入口が手前と奥に二か所ございます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessG"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">8</span>.
                            奥側の入口に入ります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessH"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">9</span>.
                            イオンバイクの右側向かいのテナントになります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessI"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'

export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの8号店です！
南行徳駅南口から徒歩8分のソコラ南行徳の1Fテナントのジムです！
ユニクロやイオンなど商業施設で買い物を楽しみながら、パーソナルトレーニングを受けられます！
現在、南行徳にお住まいの多くのお客様に通っていただいております！
初めてでも話しやすいパーソナルトレーナーが、運動初心者のあなたにとって、気を遣わないフラットなコミュニケーションで、楽しみながら受けやすいレッスンをします！`
        )
    },
    components: {
        Heading,
        FooterContents,
        ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '南行徳SOCOLA店',

            center: {
                lat: 35.668182652474265,
                lng: 139.9051400712869,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.668182652474265,
                        lng: 139.9051400712869,
                    },
                    title: '南行徳SOCOLA店',
                },
            ],
            message: `パーソナルジム REVOISTの8号店です！<br />
南行徳駅南口から徒歩8分のソコラ南行徳の1Fテナントのジムです！<br />
ユニクロやイオンなど商業施設で買い物を楽しみながら、パーソナルトレーニングを受けられます！<br />
現在、南行徳にお住まいの多くのお客様に通っていただいております！<br />
初めてでも話しやすいパーソナルトレーナーが、運動初心者のあなたにとって、気を遣わないフラットなコミュニケーションで、楽しみながら受けやすいレッスンをします！<br /><br />
「筋トレ」という言葉だけ聞くとキツイ・つらいといったイメ ージが思い浮かびますが、「筋 トレ」ほど努力が結果として表れるものはありません。お客様の悩みを解消すること、そして、お客様起点で柔軟にご対応いたします！
南行徳SOCOLA店トレーナー：伊丹弾
`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '南行徳SOCOLA店店',
                    disabled: true,
                    href: 'access-7',
                },
            ]
        },

        imageMv() {
            return 'static/pc_access_8/socola-1.jpg'
        },
        accessA() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_3/IMG_3185.png'
                : 'static/sp_access_3/IMG_3185.png'
        },
        accessB() {
            return 'static/pc_access_8/socola-2.jpg'
        },
        accessC() {
            return 'static/pc_access_8/socola-3.jpg'
        },
        accessD() {
            return 'static/pc_access_8/socola-4.jpg'
        },
        accessE() {
            return 'static/pc_access_8/socola-5.jpg'
        },
        accessF() {
            return 'static/pc_access_8/socola-6.jpg'
        },
        accessG() {
            return 'static/pc_access_8/socola-7.jpg'
        },
        accessH() {
            return 'static/pc_access_8/socola-8.jpg'
        },
        accessI() {
            return 'static/pc_access_8/socola-1.jpg'
        },
        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/MinamiGyotokuSocola.jpg'
                : 'static/landing/MinamiGyotokuSocola.jpg'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/南行徳SOCOLA店伊丹弾トレーナー.jpg'
                : 'static/landing/trainer/南行徳SOCOLA店伊丹弾トレーナー.jpg'
        },
    },
}
</script>
<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
